<template>
    <v-card width="100%" height="100%" class="rounded-0">
      <v-toolbar color="guacamole">
        <v-toolbar-title>Version Control</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="versions"
          :items-per-page="10"
          class="elevation-1"
          :item-class="getRowClass"
        >
        <template v-slot:item="{ item }">
        <tr :class="item.id === highlightedRowId ? 'highlighted-row' : ''">
          <td>{{ item.id }}</td>
          <td>{{ item.version }}</td>
          <td>{{ modifiedFormat(item.created) }}</td>
          <!-- <td>{{ item.actions }}</td> -->
          <!-- Add other columns as needed -->
          <td>
              <v-btn 
                @click.prevent="setversion(item)"
                class="ma-2"
              >
                  Set Version
              </v-btn>
              <v-btn 
                @click.prevent="delversion(item)"
                class="ma-2"
              >
                  Delete
              </v-btn>
          </td>
        </tr>
      </template>
          <!-- <template v-slot:item.actions="{ item }">
            <v-btn block @click.prevent="setversion(item)">Set Version</v-btn>
          </template> -->
          <template v-slot:item.created="{ item }">
            {{ modifiedFormat(item.created) }}
          </template>
        </v-data-table>
  
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { sync, get, call } from "vuex-pathify";
  import PassEntryCode from '@/mixins/PassEntrycode.js'
  import { DateTime } from 'luxon';

  
  export default {
    name: "VersionControl",
    mixins: [PassEntryCode],
    components: {
    },
    data: function () {
      return {
        headers: [
          {
            text: 'ID',
            value: 'id'
          },
          {
            text: 'Version',
            value: 'version'
          },
          {
            text: 'Created',
            value: 'created'
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false
          }
        ],
        highlightedRowId: 0,
      };
    },
    methods: {
        ...call('version', [
            'fetchVersionIncident',
            'setVersion',
            'delVersion',
            'doReload'
        ]),
        ...call('incidentsnew', {
          'updateIncident': 'fetchIncidentByEntryCode'
        }),
        async setversion(item) {
            this.highlightedRowId = item.id
            await this.setVersion(item.id)
            await this.updateIncident(this.entrycode)
            this.doReload()
        },
        async delversion(item) {
          await this.delVersion(item.id)
        },
        modifiedFormat (item) {
            return DateTime.fromISO(item).toFormat('dd LLL yyyy - HH:mm:ss')
        },
        getRowClass(item) {
          return item.id == this.highlightedRowId ? 'highlighted-row' : '';
        }
    },
    watch: {
        // SEE how to use the loading watcher to do
        // the updates here...
        inc(newValue, oldValue) {
          this.highlightedRowId = this.inc.data.version_id ? this.inc.data.version_id : 1
        }
    },
    async created () {
        await this.fetchVersionIncident()
    },
    mounted() {
      // console.log(this.inc.data.version_id)
      this.highlightedRowId = this.inc.data.version_id ? this.inc.data.version_id : 1
    },
    computed: {
        ...get('version',[
            'versions'
        ]),
        ...get('incidentsnew', {
          inc: 'incident',
        }),
    },
  };
  </script>

<style scoped>
.highlighted-row {
  background-color: rgb(95, 174, 27);
}
</style>
  